import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, IpRestriction, PaginationResponse } from '@bright/api';
import { selectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { IpRestrictionBulkAction, IpRestrictionStatus } from '../models';
import {
  getIpRestrictionsStatus,
  loadIpRestrictions,
  runIpRestrictionBulkAction,
  updateIpRestrictionStatus
} from '../services';

export const loadIpRestrictionsAction = createAsyncThunk<
  PaginationResponse<IpRestriction>,
  {
    queryString: string;
  },
  { state: RootState }
>(
  'ipRestrictions/loadIpRestrictions',
  async ({ queryString }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());

    try {
      return await loadIpRestrictions(orgId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().ipRestrictions, loadIpRestrictionsAction)
  }
);

export const loadIpRestrictionsStatusAction = createAsyncThunk<
  IpRestrictionStatus,
  void,
  { state: RootState }
>(
  'ipRestrictions/loadIpRestrictionsStatus',
  async (_, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());

    try {
      return await getIpRestrictionsStatus(orgId);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().ipRestrictions, loadIpRestrictionsStatusAction)
  }
);

export const updateIpRestrictionsStatusAction = createAsyncThunk<
  void,
  {
    payload: IpRestrictionStatus;
  },
  { state: RootState }
>(
  'ipRestrictions/updateIpRestrictionsStatus',
  async ({ payload }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());

    try {
      return await updateIpRestrictionStatus(orgId, payload);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const runIpRestrictionsBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: IpRestrictionBulkAction;
  },
  { state: RootState }
>(
  'ipRestrictions/runIpRestrictionsBulkAction',
  async ({ ids, action }, { getState, rejectWithValue }) => {
    const orgId = selectOwnOrganizationId(getState());

    try {
      return await runIpRestrictionBulkAction(ids, orgId, action);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);
