import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiKey, CreateApiKeyResponse } from '@bright/api';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { ManagePersonalApiKeyPayload } from '../models';
import { createPersonalApiKey, loadPersonalApiKey, updatePersonalApiKey } from '../services';

export const loadPersonalApiKeyAction = createAsyncThunk<
  ApiKey,
  {
    apiKeyId: string;
  },
  { state: RootState }
>(
  'personalApiKey/loadPersonalApiKey',
  async ({ apiKeyId }, { rejectWithValue }) => {
    try {
      return await loadPersonalApiKey(apiKeyId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().personalApiKey, loadPersonalApiKeyAction)
  }
);

export const updatePersonalApiKeyAction = createAsyncThunk<
  void,
  {
    apiKeyId: string;
    payload: ManagePersonalApiKeyPayload;
  },
  { state: RootState }
>(
  'personalApiKey/updatePersonalApiKey',
  async ({ apiKeyId, payload }, { rejectWithValue }) => {
    try {
      return await updatePersonalApiKey(apiKeyId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().personalApiKey, updatePersonalApiKeyAction)
  }
);

export const createPersonalApiKeyAction = createAsyncThunk<
  CreateApiKeyResponse,
  {
    payload: ManagePersonalApiKeyPayload;
  },
  { state: RootState }
>(
  'personalApiKey/createPersonalApiKey',
  async ({ payload }, { rejectWithValue }) => {
    try {
      return await createPersonalApiKey(payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().personalApiKey, createPersonalApiKeyAction)
  }
);
