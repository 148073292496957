import { gridClasses } from '@mui/x-data-grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { boxClasses } from '@mui/material/Box';
import { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import { buttonClasses } from '@mui/material/Button';
import { buttonGroupClasses } from '@mui/material/ButtonGroup';
import { checkboxClasses } from '@mui/material/Checkbox';
import { dividerClasses } from '@mui/material/Divider';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { formLabelClasses } from '@mui/material/FormLabel';
import { inputBaseClasses } from '@mui/material/InputBase';
import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { listItemTextClasses } from '@mui/material/ListItemText';
import { menuItemClasses } from '@mui/material/MenuItem';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { radioClasses } from '@mui/material/Radio';
import { selectClasses } from '@mui/material/Select';
import { stackClasses } from '@mui/material/Stack';
import { switchClasses } from '@mui/material/Switch';
import { toggleButtonClasses } from '@mui/material/ToggleButton';
import type { ThemeOptions } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import type { Components } from '@mui/material/styles/components';
import type { Theme } from '@mui/material/styles/createTheme';
import type { DataGridProComponents } from '@mui/x-data-grid-pro/themeAugmentation';
import { customGridClasses } from '../../types';
import { brightDark } from './colors';

const ICON_XS_FONT_SIZE = 16;

export const componentsOptions: ThemeOptions = {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary
        })
      },
      variants: [
        {
          props: { variant: 'h2' },
          style: ({ theme }) => ({ color: theme.palette.custom.title })
        },
        {
          props: { variant: 'h3' },
          style: ({ theme }) => ({ color: theme.palette.custom.title })
        },
        {
          props: { variant: 'h4' },
          style: ({ theme }) => ({ color: theme.palette.custom.title })
        },
        {
          props: { variant: 'subtitle1Medium' },
          style: ({ theme }) => ({ color: theme.palette.custom.title })
        },
        {
          props: { variant: 'subtitle2Medium' },
          style: ({ theme }) => ({ color: theme.palette.custom.title })
        },
        {
          props: { variant: 'body1Medium' },
          style: ({ theme }) => ({ color: theme.palette.custom.title })
        },
        {
          props: { variant: 'body2Medium' },
          style: ({ theme }) => ({ color: theme.palette.custom.title })
        },
        {
          props: { variant: 'buttonMedium' },
          style: ({ theme }) => ({ color: theme.palette.custom.title })
        }
      ]
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.custom.input.helperText.color,
            [`&.${formHelperTextClasses.error}`]: {
              color: theme.palette.custom.input.helperText.error
            }
          };
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: 'none',
          fontSize: theme.typography.body2.fontSize
        }),
        detailPanel: {
          backgroundColor: 'inherit',
          overflow: 'visible'
        },
        row: ({ theme }) => ({
          borderBottom: 'none',
          '&:hover, &.Mui-hovered': {
            backgroundColor: theme.palette.custom.table.hoverBg,
            '&.Mui-selected': {
              backgroundColor: `${theme.palette.custom.table.hoverBg}!important`
            }
          },
          '&.Mui-selected': {
            backgroundColor: `${theme.palette.custom.table.selectedBg}!important`
          },
          [`&.${customGridClasses.oddRow}:not(:hover):not(.Mui-hovered)`]: {
            backgroundColor: theme.palette.custom.table.oddRowBg
          },
          [`&.${customGridClasses.evenRow}:not(:hover):not(.Mui-hovered)`]: {
            backgroundColor: theme.palette.custom.table.evenRowBg
          },
          [`&.${customGridClasses.cursorPointer}`]: {
            cursor: 'pointer'
          }
        }),
        cell: () => ({
          borderBottom: 'none',

          '&:focus-visible, &:focus-within': {
            outline: 'none !important'
          },
          [`&.${customGridClasses.cursorPointer}`]: {
            cursor: 'pointer'
          },
          [`&.${customGridClasses.cursorNotAllowed}`]: {
            cursor: 'not-allowed'
          }
        }),
        main: ({ theme }) => ({
          [`& .${gridClasses.columnHeaders}`]: {
            color: theme.palette.custom.table.columnHeaderFg,
            backgroundColor: theme.palette.custom.table.columnHeaderBg,
            borderColor: theme.palette.custom.table.columnHeaderBorder,
            [`& .${gridClasses.columnHeader}`]: {
              '&:focus-visible, &:focus-within': {
                outline: 'none !important'
              }
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              fontWeight: theme.typography.body2Medium.fontWeight
            },
            [`& .${gridClasses['columnSeparator--sideRight']}`]: {
              right: '-9px'
            },
            borderRadius: 0
          },
          [`& .${gridClasses.virtualScroller}`]: {
            minHeight: '190px'
          },
          [`& .${gridClasses.pinnedColumns}`]: {
            minHeight: 'auto!important',
            boxShadow: `2px -2px 4px -2px ${alpha(theme.palette.common.black, 0.21)}`
          },
          [`& .${gridClasses.pinnedColumnHeaders}`]: {
            backgroundColor: theme.palette.custom.table.columnHeaderBg
          },
          [`& .${gridClasses.cell}`]: {
            [`&.${gridClasses['cell--editable']}`]: {
              cursor: 'text',
              '&:hover': {
                // `stackClasses.root` selector here is for TextWithTitlePopover
                // `boxClasses.root` selector here is for TextWithEllipsis
                [`& > .${gridClasses.cellContent}, & > .${stackClasses.root}, & > .${boxClasses.root}`]:
                  {
                    border: `1px solid ${theme.palette.custom.table.cellEditorBorder}`,
                    borderRadius: theme.spacing(0.5),
                    padding: theme.spacing(0.75, 1),
                    lineHeight: '18px',
                    width: '100%'
                  }
              },
              [`&.${gridClasses['cell--editing']}`]: {
                backgroundColor: 'initial',
                boxShadow: 'none',
                [`& .${gridClasses.editInputCell}`]: {
                  fontSize: 'inherit',

                  '& input': {
                    height: '30px',
                    lineHeight: '30px',
                    border: `1px solid ${theme.palette.custom.table.cellEditorBorderFocus}`,
                    borderRadius: theme.spacing(0.5),
                    padding: theme.spacing(0, 1)
                  }
                }
              }
            }
          }
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.custom.table.rowBorder
        })
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      variants: [
        {
          props: { size: 'small' },
          style: ({ theme }) => ({
            fontSize: theme.typography.button2.fontSize
          })
        },
        {
          props: { size: 'medium' },
          style: ({ theme }) => ({
            fontSize: theme.typography.button2.fontSize
          })
        },
        {
          props: { size: 'large' },
          style: ({ theme }) => ({
            fontSize: theme.typography.buttonMedium.fontSize
          })
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.custom.button.primary.state.enabled.bg,
            color: theme.palette.custom.button.primary.state.enabled.fg,
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: theme.palette.custom.button.primary.state.disabled.bg,
              color: theme.palette.custom.button.primary.state.disabled.fg
            },
            '&:hover': {
              backgroundColor: theme.palette.custom.button.primary.state.hover.bg,
              color: theme.palette.custom.button.primary.state.hover.fg
            },
            [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
              backgroundColor: theme.palette.custom.button.primary.state.focus.bg,
              color: theme.palette.custom.button.primary.state.focus.fg
            }
          })
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.custom.button.primaryError.state.enabled.bg,
            color: theme.palette.custom.button.primaryError.state.enabled.fg,
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: theme.palette.custom.button.primaryError.state.disabled.bg,
              color: theme.palette.custom.button.primaryError.state.disabled.fg
            },
            '&:hover': {
              backgroundColor: theme.palette.custom.button.primaryError.state.hover.bg,
              color: theme.palette.custom.button.primaryError.state.hover.fg
            },
            [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
              backgroundColor: theme.palette.custom.button.primaryError.state.focus.bg,
              color: theme.palette.custom.button.primaryError.state.focus.fg
            }
          })
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.custom.button.primaryWarning.state.enabled.bg,
            color: theme.palette.custom.button.primaryWarning.state.enabled.fg,
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: theme.palette.custom.button.primaryWarning.state.disabled.bg,
              color: theme.palette.custom.button.primaryWarning.state.disabled.fg
            },
            '&:hover': {
              backgroundColor: theme.palette.custom.button.primaryWarning.state.hover.bg,
              color: theme.palette.custom.button.primaryWarning.state.hover.fg
            },
            [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
              backgroundColor: theme.palette.custom.button.primaryWarning.state.focus.bg,
              color: theme.palette.custom.button.primaryWarning.state.focus.fg
            }
          })
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.custom.button.primaryInfo.state.enabled.bg,
            color: theme.palette.custom.button.primaryInfo.state.enabled.fg,
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: theme.palette.custom.button.primaryInfo.state.disabled.bg,
              color: theme.palette.custom.button.primaryInfo.state.disabled.fg
            },
            '&:hover': {
              backgroundColor: theme.palette.custom.button.primaryInfo.state.hover.bg,
              color: theme.palette.custom.button.primaryInfo.state.hover.fg
            },
            [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
              backgroundColor: theme.palette.custom.button.primaryInfo.state.focus.bg,
              color: theme.palette.custom.button.primaryInfo.state.focus.fg
            }
          })
        },

        {
          props: { variant: 'text', color: 'primary' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.custom.button.tertiaryPrimary.state.enabled.bg,
            color: theme.palette.custom.button.tertiaryPrimary.state.enabled.fg,
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: theme.palette.custom.button.tertiaryPrimary.state.disabled.bg,
              color: theme.palette.custom.button.tertiaryPrimary.state.disabled.fg
            },
            '&:hover': {
              backgroundColor: theme.palette.custom.button.tertiaryPrimary.state.hover.bg,
              color: theme.palette.custom.button.tertiaryPrimary.state.hover.fg
            },
            [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
              backgroundColor: theme.palette.custom.button.tertiaryPrimary.state.focus.bg,
              color: theme.palette.custom.button.tertiaryPrimary.state.focus.fg
            }
          })
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.custom.button.tertiarySecondary.state.enabled.bg,
            color: theme.palette.custom.button.tertiarySecondary.state.enabled.fg,
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: theme.palette.custom.button.tertiarySecondary.state.disabled.bg,
              color: theme.palette.custom.button.tertiarySecondary.state.disabled.fg
            },
            '&:hover': {
              backgroundColor: theme.palette.custom.button.tertiarySecondary.state.hover.bg,
              color: theme.palette.custom.button.tertiarySecondary.state.hover.fg
            },
            [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
              backgroundColor: theme.palette.custom.button.tertiarySecondary.state.focus.bg,
              color: theme.palette.custom.button.tertiarySecondary.state.focus.fg
            }
          })
        },
        {
          props: { variant: 'text', color: 'error' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.custom.button.tertiaryError.state.enabled.bg,
            color: theme.palette.custom.button.tertiaryError.state.enabled.fg,
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: theme.palette.custom.button.tertiaryError.state.disabled.bg,
              color: theme.palette.custom.button.tertiaryError.state.disabled.fg
            },
            '&:hover': {
              backgroundColor: theme.palette.custom.button.tertiaryError.state.hover.bg,
              color: theme.palette.custom.button.tertiaryError.state.hover.fg
            },
            [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
              backgroundColor: theme.palette.custom.button.tertiaryError.state.focus.bg,
              color: theme.palette.custom.button.tertiaryError.state.focus.fg
            }
          })
        }
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          lineHeight: 'normal',
          padding: theme.spacing(0, 1.5)
        }),
        outlined: ({ theme }) => ({
          [`&.${buttonClasses.outlinedPrimary}`]: {
            borderColor: theme.palette.custom.button.outlinedPrimary.border
          },
          [`&.${buttonClasses.outlinedSecondary}`]: {
            backgroundColor: theme.palette.custom.button.outlinedSecondary.state.enabled.bg,
            color: theme.palette.custom.button.outlinedSecondary.state.enabled.fg,
            borderColor: theme.palette.custom.button.outlinedSecondary.border
          },
          borderWidth: 1,
          borderRadius: theme.shape.borderRadius,
          [`&.${buttonClasses.startIcon}`]: {
            marginLeft: theme.spacing(-0.5)
          },
          [`&.${buttonClasses.disabled}`]: {
            backgroundColor: theme.palette.custom.button.outlinedSecondary.state.disabled.bg,
            color: theme.palette.custom.button.outlinedSecondary.state.disabled.fg,
            borderColor: theme.palette.custom.button.outlinedSecondary.border
          },
          '&:hover': {
            [`&.${buttonClasses.outlinedSecondary}`]: {
              backgroundColor: theme.palette.custom.button.outlinedSecondary.state.hover.bg,
              color: theme.palette.custom.button.outlinedSecondary.state.hover.fg,
              borderColor: theme.palette.custom.button.outlinedSecondary.border
            }
          },
          [`&:focus-visible, &.${buttonClasses.focusVisible}`]: {
            [`&.${buttonClasses.outlinedPrimary}`]: {
              backgroundColor: theme.palette.custom.button.outlinedPrimary.state.focus.bg
            },
            [`&.${buttonClasses.outlinedSecondary}`]: {
              backgroundColor: theme.palette.custom.button.outlinedSecondary.state.focus.bg,
              color: theme.palette.custom.button.outlinedSecondary.state.focus.fg,
              borderColor: theme.palette.custom.button.outlinedSecondary.border
            }
          }
        }),
        contained: ({ theme }) => ({
          fontWeight: theme.typography.buttonMedium.fontWeight,
          letterSpacing: theme.typography.buttonMedium.letterSpacing
        }),
        sizeSmall: {
          height: 32
        },
        sizeMedium: {
          height: 40
        }
      }
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: '/'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: brightDark['200'],
          fontSize: 'inherit',
          fontWeight: 'inherit',

          [`.${breadcrumbsClasses.separator}`]: {
            margin: `0 ${theme.spacing(1)} 0 ${theme.spacing(0.75)}`
          },
          [`.${breadcrumbsClasses.ol}`]: {
            flexWrap: 'nowrap'
          },
          [`.${breadcrumbsClasses.li}`]: {
            whiteSpace: 'nowrap',
            lineHeight: '28px',
            height: 32,
            display: 'inline-flex',
            alignItems: 'center',
            [`&:last-of-type`]: {
              overflow: 'hidden',
              color: theme.palette.text.primary
            }
          }
        })
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        grouped: {
          minWidth: '30px'
        },
        contained: ({ theme }) => ({
          [`& .${buttonGroupClasses.firstButton},& .${buttonGroupClasses.middleButton}`]: {
            borderRightColor: theme.palette.custom.splitButton.primary.divider
          }
        })
      }
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'xxs' },
          style: { fontSize: 14 }
        },
        {
          props: { fontSize: 'xs' },
          style: { fontSize: ICON_XS_FONT_SIZE }
        },
        {
          props: { fontSize: 'small' },
          style: { fontSize: 18 }
        },
        {
          props: { fontSize: 'medium' },
          style: { fontSize: 20 }
        },
        {
          props: { fontSize: 'large' },
          style: { fontSize: 22 }
        },
        {
          props: { fontSize: 'xl' },
          style: { fontSize: 24 }
        }
      ]
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <CheckCircleOutlineIcon fontSize="xs" />,
          error: <ErrorOutlineIcon fontSize="xs" />,
          info: <InfoOutlinedIcon fontSize="xs" />,
          warning: <WarningAmberIcon fontSize="xs" />
        }
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1, 2),
          alignItems: 'center'
        }),
        icon: { padding: 0, fontSize: ICON_XS_FONT_SIZE },
        message: { padding: 0, fontWeight: 500 }
      }
    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.${customGridClasses.columnMenu}`]: {
            padding: 0,
            minWidth: 171,
            [`& .${dividerClasses.root}`]: {
              display: 'none'
            },
            [`& .${menuItemClasses.root}`]: {
              padding: theme.spacing(1.5),
              height: 40
            },
            [`& .${listItemIconClasses.root}`]: {
              minWidth: 0,
              marginRight: theme.spacing(1),
              '& svg': {
                fontSize: ICON_XS_FONT_SIZE
              }
            }
          }
        })
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          minWidth: 48,
          [`&.${toggleButtonClasses.sizeMedium}`]: {
            fontSize: 16
          },
          '&.Mui-selected': {
            fontWeight: 500
          }
        }
      }
    },
    MuiListItem: {
      defaultProps: {
        dense: true
      },
      styleOverrides: {
        root: {
          minHeight: 40,
          [`& .${listItemButtonClasses.root}`]: {
            minHeight: 'inherit'
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0
        }
      }
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 40,
          color: theme.palette.custom.menuListItem.primary.enabled.fg,
          whiteSpace: 'pre',
          [`& .${listItemIconClasses.root}`]: {
            color: theme.palette.custom.icon
          },
          '&:hover': {
            backgroundColor: theme.palette.custom.menuListItem.primary.enabled.hover.bg
          },
          [`&.${menuItemClasses.selected}`]: {
            color: theme.palette.custom.menuListItem.primary.selected.fg,
            [`& .${listItemTextClasses.primary}`]: {
              color: 'inherit'
            },
            [`& .${listItemIconClasses.root}`]: {
              color: 'inherit'
            },
            backgroundColor: theme.palette.custom.menuListItem.primary.selected.bg,
            '&:hover': {
              backgroundColor: theme.palette.custom.menuListItem.primary.selected.hover.bg
            }
          }
        })
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center'
        }
      }
    },
    MuiFormControlLabel: {
      defaultProps: {
        componentsProps: {
          typography: { variant: 'body2' }
        }
      }
    },
    MuiDialogTitle: {
      variants: [
        {
          props: { variant: 'h1' },
          style: ({ theme }) => ({
            padding: theme.spacing(2, 3)
          })
        }
      ],
      defaultProps: {
        variant: 'h2'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(3)
        })
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0, 3, 3)
        })
      }
    },
    MuiDialogContentText: {
      defaultProps: {
        variant: 'body2'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary
        })
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0, 3, 3)
        })
      }
    },
    MuiInputBase: {
      variants: [
        {
          props: { size: 'small' },
          style: ({ theme }) => ({
            fontSize: 12,
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            [`.${outlinedInputClasses.notchedOutline} legend>span`]: {
              fontSize: 12
            }
          })
        },
        {
          props: { size: 'medium' },
          style: {
            fontSize: 14,
            [`.${outlinedInputClasses.notchedOutline} legend>span`]: {
              fontSize: 12
            }
          }
        },
        {
          props: { size: 'large' },
          style: { fontSize: 16 }
        },
        {
          props: { color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.custom.input.text,
            [`.${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.custom.input.outline.color
            },
            [`&.${inputBaseClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.custom.input.outline.color
            },
            [`&.${inputBaseClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.custom.input.outline.focused
            },
            [`&.${inputBaseClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: `${theme.palette.custom.input.outline.error}!important`
            },
            [`& .${selectClasses.icon}`]: {
              color: theme.palette.custom.select.popupIndicator
            },
            [`.${autocompleteClasses.popupIndicator}`]: {
              color: theme.palette.custom.select.popupIndicator
            },
            [`.${autocompleteClasses.clearIndicator}`]: {
              color: theme.palette.custom.select.clearIndicator
            }
          })
        }
      ]
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: ({ theme }) => ({
          color: theme.palette.custom.switch.enabled.base.thumb,
          [`&.${switchClasses.checked}`]: {
            color: theme.palette.custom.switch.enabled.selected.thumb,
            '&:hover': {
              backgroundColor: alpha(
                theme.palette.custom.switch.enabled.selected.thumb,
                theme.palette.action.hoverOpacity
              )
            },
            [`& + .${switchClasses.track}`]: {
              backgroundColor: theme.palette.custom.switch.enabled.selected.track
            }
          },
          [`&.${switchClasses.disabled}`]: {
            color: theme.palette.custom.switch.disabled.base.thumb,
            [`& + .${switchClasses.track}`]: {
              backgroundColor: theme.palette.custom.switch.disabled.base.track,
              opacity: 1
            },
            [`& .${switchClasses.thumb}`]: {
              boxShadow: 'none'
            },
            [`&.${switchClasses.checked}`]: {
              color: theme.palette.custom.switch.disabled.selected.thumb,
              [`& + .${switchClasses.track}`]: {
                backgroundColor: theme.palette.custom.switch.disabled.selected.track,
                opacity: 1
              }
            }
          }
        }),
        track: ({ theme }) => ({
          backgroundColor: theme.palette.custom.switch.enabled.base.track
        })
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.custom.checkbox.unchecked.enabled,
          '&:hover': {
            backgroundColor: theme.palette.custom.checkbox.unchecked.hover
          },
          [`&.${checkboxClasses.checked}`]: {
            color: theme.palette.custom.checkbox.checked.enabled,
            [`&.${checkboxClasses.disabled}`]: {
              color: theme.palette.custom.checkbox.checked.disabled
            },
            '&:hover': {
              backgroundColor: theme.palette.custom.checkbox.checked.hover
            }
          },
          [`&.${checkboxClasses.disabled}`]: {
            color: theme.palette.custom.checkbox.unchecked.disabled
          }
        })
      }
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.custom.checkbox.unchecked.enabled,
          '&:hover': {
            backgroundColor: theme.palette.custom.checkbox.unchecked.hover
          },
          [`&.${radioClasses.checked}`]: {
            color: theme.palette.custom.checkbox.checked.enabled,
            [`&.${radioClasses.disabled}`]: {
              color: theme.palette.custom.checkbox.checked.disabled
            },
            '&:hover': {
              backgroundColor: theme.palette.custom.checkbox.checked.hover
            }
          },
          [`&.${radioClasses.disabled}`]: {
            color: theme.palette.custom.checkbox.unchecked.disabled
          }
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          lineHeight: theme.spacing(2.5),
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.25, 0.75),
          opacity: '0.95 !important',
          backgroundColor: theme.palette.custom.tooltip.dark.bg,
          color: theme.palette.custom.tooltip.dark.fg,
          boxShadow: `0px 2px 4px 0px ${theme.palette.custom.tooltip.shadow}`
        }),
        arrow: ({ theme }) => ({
          '&::before': {
            boxShadow: `0px 0px 3px 1px ${theme.palette.custom.tooltip.shadow}`
          }
        })
      }
    },
    MuiFormLabel: {
      variants: [
        {
          props: { color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.custom.input.label.color,
            [`&.${formLabelClasses.focused}`]: {
              color: theme.palette.custom.input.label.focused
            },
            [`&.${formLabelClasses.disabled}`]: {
              color: theme.palette.custom.input.label.disabled
            },
            [`&.${formLabelClasses.error}`]: {
              color: theme.palette.custom.input.label.error
            },
            [`&.${formLabelClasses.filled}, &.${formLabelClasses.focused}`]: {
              [`.${formLabelClasses.asterisk}`]: {
                color: theme.palette.custom.input.label.error
              }
            },
            [`.${formLabelClasses.asterisk}`]: {
              color: theme.palette.custom.input.label.asterisk
            }
          })
        }
      ]
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: ({ theme }) => ({
          color: theme.palette.custom.menuListItem.primary.enabled.fg,
          fontSize: 14,
          minHeight: 40,
          '&:hover': {
            backgroundColor: theme.palette.custom.menuListItem.primary.enabled.hover.bg
          },
          '&[aria-selected="true"]': {
            color: theme.palette.custom.menuListItem.primary.selected.fg,
            backgroundColor: theme.palette.custom.menuListItem.primary.selected.bg,
            '&:hover': {
              backgroundColor: theme.palette.custom.menuListItem.primary.selected.hover.bg
            }
          }
        })
      }
    },
    MuiChip: {
      variants: [
        {
          props: { size: 'small' },
          style: { fontSize: 13 }
        },
        {
          props: { variant: 'filled' },
          style: ({ theme }) => ({ backgroundColor: theme.palette.custom.chipBg })
        }
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(0.5)
        })
      }
    }
  } as Components<DataGridProComponents & Omit<Theme, 'components'>>
};
