import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type {
  ApiError,
  ApiKeysBulkAction,
  ExportFormat,
  OrgApiKey,
  PaginationResponse
} from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import { exportOrgApiKeys, loadOrgApiKeys, runOrgApiKeysBulkAction } from '../services';

export const loadOrgApiKeysAction = createAsyncThunk<
  PaginationResponse<OrgApiKey>,
  {
    queryString: string;
  },
  { state: RootState }
>(
  'orgApiKeys/loadOrgApiKeys',
  async ({ queryString }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());
    try {
      return await loadOrgApiKeys(orgId, queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().orgApiKeys, loadOrgApiKeysAction)
  }
);

export const runOrgApiKeysBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: ApiKeysBulkAction;
  },
  { state: RootState }
>('orgApiKeys/runOrgApiKeysBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runOrgApiKeysBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportOrgApiKeysAction = createAsyncThunk<
  void,
  {
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('orgApiKeys/exportOrgApiKeysAction', async ({ ids, format }, { getState, rejectWithValue }) => {
  const orgId = legacySelectOwnOrganizationId(getState());
  try {
    const response = await exportOrgApiKeys(orgId, format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
