import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type { ApiError, Application, ExportFormat, ID } from '@bright/api';
import {
  createApplication,
  deleteApplication,
  exportApplication,
  loadApplication,
  updateApplication
} from '@bright/applications';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders,
  toLongId
} from '@bright/core';
import type { ManageApplicationPayload } from '../models';
import { loadApplicationsAction } from './applications.actions';

export const loadApplicationAction = createAsyncThunk<Application, ID, { state: RootState }>(
  'applications/loadApplication',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await loadApplication(id);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().applications, loadApplicationsAction)
  }
);

export const createApplicationAction = createAsyncThunk<
  ID,
  {
    payload: ManageApplicationPayload;
  },
  { state: RootState }
>('application/createApplication', async ({ payload }, { getState, rejectWithValue }) => {
  const orgId = legacySelectOwnOrganizationId(getState());

  try {
    return await createApplication(toLongId(orgId), payload);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const updateApplicationAction = createAsyncThunk<
  void,
  {
    payload: ManageApplicationPayload;
  } & ID,
  { state: RootState }
>('application/updateApplication', async ({ id, payload }, { rejectWithValue }) => {
  try {
    await updateApplication(id, payload);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const deleteApplicationAction = createAsyncThunk<void, ID, { state: RootState }>(
  'application/deleteApplication',
  async ({ id }, { rejectWithValue }) => {
    try {
      await deleteApplication(id);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  }
);

export const exportApplicationAction = createAsyncThunk<
  void,
  {
    format: ExportFormat;
  },
  { state: RootState }
>('application/exportApplicationAction', async ({ format }, { getState, rejectWithValue }) => {
  const orgId = legacySelectOwnOrganizationId(getState());

  try {
    const response = await exportApplication(toLongId(orgId), format);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
