import type { RouteObject } from 'react-router-dom';

export const applicationsRoutes: RouteObject[] = [
  {
    path: 'applications',
    async lazy() {
      const { ApplicationsPage } = await import('./pages');
      return { Component: ApplicationsPage };
    },
    children: [
      {
        path: 'orgs',
        children: [
          {
            path: ':orgId',
            async lazy() {
              const { OrganizationWidget } = await import('./components/OrganizationWidget');
              return { Component: OrganizationWidget };
            }
          }
        ]
      },
      {
        path: 'apps',
        children: [
          {
            // Avoid application menu updates and re-loading, re-rendering
            path: ':internalAppId',
            async lazy() {
              const { ApplicationWidget } = await import('./components/ApplicationWidget');
              return { Component: ApplicationWidget };
            },
            children: [
              {
                path: 'details',
                async lazy() {
                  const { ApplicationDetailPage } = await import('./pages/ApplicationDetailPage');
                  return { Component: ApplicationDetailPage };
                }
              },
              {
                path: 'permissions',
                async lazy() {
                  const { ApplicationPermissionsPage } = await import('./pages/PermissionsPage');
                  return { Component: ApplicationPermissionsPage };
                }
              }
            ]
          }
        ]
      }
    ]
  }
];
