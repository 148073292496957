import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import type { SsoBrightMetadata, SsoSettings } from '../models';
import {
  createSsoSettingsAction,
  loadBrightMetadataAction,
  loadSsoSettingsAction,
  skipSsoTestAction,
  updateSsoSettingsAction
} from './sso-settings.actions';

export interface SsoSettingsState {
  readonly ssoSettings: SsoSettings | null;
  readonly brightMetadata: SsoBrightMetadata | null;
  readonly tested: boolean;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialSsoSettingsState: SsoSettingsState = {
  ssoSettings: null,
  brightMetadata: null,
  tested: true,
  pending: [],
  error: null
};

export const ssoSettingsSlice = createSlice({
  name: 'ssoSettings',
  initialState: initialSsoSettingsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSsoSettingsAction.pending, (state, action) => ({
      ...state,
      ssoSettings: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadSsoSettingsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          ssoSettings: action.payload ?? null
        },
        loadSsoSettingsAction
      )
    );
    builder.addCase(loadSsoSettingsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadSsoSettingsAction
      )
    );

    builder.addCase(updateSsoSettingsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateSsoSettingsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          ssoSettings: action.payload,
          tested: false
        },
        updateSsoSettingsAction
      )
    );
    builder.addCase(updateSsoSettingsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateSsoSettingsAction
      )
    );

    builder.addCase(createSsoSettingsAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createSsoSettingsAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          ssoSettings: action.payload,
          tested: false
        },
        createSsoSettingsAction
      )
    );

    builder.addCase(createSsoSettingsAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createSsoSettingsAction
      )
    );

    builder.addCase(skipSsoTestAction.pending, state => ({
      ...state,
      tested: true
    }));

    builder.addCase(loadBrightMetadataAction.pending, (state, action) => ({
      ...state,
      brightMetadata: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadBrightMetadataAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          brightMetadata: action.payload
        },
        loadBrightMetadataAction
      )
    );
    builder.addCase(loadBrightMetadataAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadBrightMetadataAction
      )
    );
  }
});

export const ssoSettingsReducer = ssoSettingsSlice.reducer;
