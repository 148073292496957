import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ApiError, ApiKey, CreateApiKeyResponse } from '@bright/api';
import { legacySelectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { ManageOrgApiKeyPayload } from '../models';
import { createOrgApiKey, loadApiKey, updateApiKey, updateApiKeyName } from '../services';

export const loadOrgApiKeyAction = createAsyncThunk<
  ApiKey,
  {
    apiKeyId: string;
  },
  { state: RootState }
>(
  'orgApiKey/loadApiKey',
  async ({ apiKeyId }, { rejectWithValue }) => {
    try {
      return await loadApiKey(apiKeyId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().orgApiKey, loadOrgApiKeyAction)
  }
);

export const updateOrgApiKeyNameAction = createAsyncThunk<
  { apiKeyId: string; name: string },
  {
    apiKeyId: string;
    data: Pick<ApiKey, 'name'>;
  },
  { state: RootState }
>('orgApiKey/updateOrgApiKeyName', async ({ apiKeyId, data }, { rejectWithValue }) => {
  try {
    await updateApiKeyName(apiKeyId, data);
    return { apiKeyId, name: data.name };
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const updateOrgApiKeyAction = createAsyncThunk<
  void,
  {
    apiKeyId: string;
    payload: ManageOrgApiKeyPayload;
  },
  { state: RootState }
>(
  'orgApiKey/updateOrgApiKey',
  async ({ apiKeyId, payload }, { rejectWithValue }) => {
    try {
      return await updateApiKey(apiKeyId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().orgApiKey, updateOrgApiKeyAction)
  }
);

export const createOrgApiKeyAction = createAsyncThunk<
  CreateApiKeyResponse,
  {
    payload: ManageOrgApiKeyPayload;
  },
  { state: RootState }
>(
  'orgApiKey/createOrgApiKey',
  async ({ payload }, { getState, rejectWithValue }) => {
    const orgId = legacySelectOwnOrganizationId(getState());
    try {
      return await createOrgApiKey(orgId, payload);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().orgApiKey, createOrgApiKeyAction)
  }
);
