import type { AxiosResponse } from 'axios';
import type {
  ApiKey,
  CreateApiKeyResponse,
  ExportFormat,
  ID,
  OrgApiKey,
  PaginationResponse
} from '@bright/api';
import { ApiKeysBulkAction } from '@bright/api';
import { axiosInstance } from '@bright/core';
import type { ApiKeyDetails, ManageOrgApiKeyPayload } from '../models';

export const loadOrgApiKeys = async (
  orgId: string,
  queryString: string
): Promise<PaginationResponse<OrgApiKey>> => {
  const response = await axiosInstance.get<PaginationResponse<OrgApiKey>>(
    `/rest/v1/orgs/${orgId}/api-keys?${queryString}`
  );
  return response.data;
};

export const loadApiKey = async (apiKeyId: string): Promise<ApiKeyDetails> => {
  const response = await axiosInstance.get<ApiKeyDetails>(`/rest/v1/api-keys/${apiKeyId}`);
  return response.data;
};

export const updateApiKeyName = async (
  apiKeyId: string,
  data: Pick<ApiKey, 'name'>
): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/api-keys/${apiKeyId}`, data);
};

export const runOrgApiKeysBulkAction = async (
  ids: string[],
  action: ApiKeysBulkAction
): Promise<string[] | void> => {
  let response: AxiosResponse<ID[] | void>;
  switch (action) {
    case ApiKeysBulkAction.EXTEND:
    case ApiKeysBulkAction.ACTIVATE:
    case ApiKeysBulkAction.SUSPEND:
    case ApiKeysBulkAction.REVOKE:
    case ApiKeysBulkAction.DELETE:
      response = await axiosInstance.post<void>(`/rest/v1/api-keys/${action}`, {
        ids
      });
      break;

    default: {
      throw new Error(`Unknown api-keys bulk action: ${action}`);
    }
  }

  return Array.isArray(response.data) ? response.data.map(({ id }) => id) : undefined;
};

export const exportOrgApiKeys = async (
  orgId: string,
  format: ExportFormat,
  ids: string[]
): Promise<AxiosResponse<Blob, any>> => {
  return axiosInstance.post<Blob>(
    `/rest/v1/orgs/${orgId}/api-keys/export`,
    {
      ids,
      format
    },
    {
      responseType: 'blob'
    }
  );
};

export const createOrgApiKey = async (
  orgId: string,
  payload: ManageOrgApiKeyPayload
): Promise<CreateApiKeyResponse> => {
  return (
    await axiosInstance.post<CreateApiKeyResponse>(`/rest/v1/orgs/${orgId}/api-keys`, payload)
  ).data;
};

export const updateApiKey = async (
  apiKeyId: string,
  payload: ManageOrgApiKeyPayload
): Promise<void> => {
  await axiosInstance.patch<void>(`/rest/v1/api-keys/${apiKeyId}`, payload);
};
