import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, ApiKey } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  exportPersonalApiKeysAction,
  loadPersonalApiKeysAction,
  runPersonalApiKeysBulkActionAction,
  updatePersonalApiKeyNameAction
} from './personal-api-keys.actions';

export interface PersonalApiKeysState {
  readonly items: ApiKey[];
  readonly total: number;
  readonly pending: Action[];
  readonly next?: string;
  readonly previous?: string;
  readonly error: ApiError | null;
}

const initialPersonalApiKeysState: PersonalApiKeysState = {
  items: [],
  total: 0,
  pending: [],
  error: null
};

export const personalApiKeysSlice = createSlice({
  name: 'personalApiKeys',
  initialState: initialPersonalApiKeysState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadPersonalApiKeysAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadPersonalApiKeysAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          items: action.payload.items,
          total: action.payload.total,
          previous: action.payload.previous,
          next: action.payload.next
        },
        loadPersonalApiKeysAction
      )
    );
    builder.addCase(loadPersonalApiKeysAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadPersonalApiKeysAction
      )
    );

    builder.addCase(updatePersonalApiKeyNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updatePersonalApiKeyNameAction.fulfilled, state =>
      cleanupPendingState(state, updatePersonalApiKeyNameAction)
    );
    builder.addCase(updatePersonalApiKeyNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updatePersonalApiKeyNameAction
      )
    );

    builder.addCase(runPersonalApiKeysBulkActionAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(runPersonalApiKeysBulkActionAction.fulfilled, state =>
      cleanupPendingState(state, runPersonalApiKeysBulkActionAction)
    );
    builder.addCase(
      runPersonalApiKeysBulkActionAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          runPersonalApiKeysBulkActionAction
        )
    );

    builder.addCase(exportPersonalApiKeysAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(exportPersonalApiKeysAction.fulfilled, state =>
      cleanupPendingState(state, exportPersonalApiKeysAction)
    );
    builder.addCase(exportPersonalApiKeysAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        exportPersonalApiKeysAction
      )
    );
  }
});

export const personalApiKeysReducer = personalApiKeysSlice.reducer;
