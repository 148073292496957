import type { Action } from '@reduxjs/toolkit';

export function cleanupPending<T extends Action>(
  pending: T[],
  type: string,
  clearAll: boolean = false
): T[] {
  if (clearAll) {
    return pending.filter((pendedAction: T) => type !== pendedAction.type);
  }
  const pendedIdx = pending.findIndex((pendedAction: T) => type === pendedAction.type);
  return pendedIdx === -1
    ? pending
    : pending.filter((_pendedAction: T, idx: number) => idx !== pendedIdx);
}

export function cleanupPendingState<T extends { pending: Action[] }>(
  state: T,
  action: { pending: Action }
): T {
  return {
    ...state,
    pending: cleanupPending(state.pending, action.pending.type)
  };
}

export const actionNotPending = (
  state: { pending: Action[] },
  actionOrFn: { pending: Action } | ((action: Action) => boolean)
): boolean => {
  return !state.pending.some(
    'pending' in actionOrFn
      ? (pendingAction: Action) => pendingAction.type === actionOrFn.pending.type
      : actionOrFn
  );
};
