import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, ApiKey } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  createOrgApiKeyAction,
  loadOrgApiKeyAction,
  updateOrgApiKeyAction,
  updateOrgApiKeyNameAction
} from './api-key.actions';

interface OrgApiKeyState {
  readonly apiKey: ApiKey | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialOrgApiKeyState: OrgApiKeyState = {
  apiKey: null,
  pending: [],
  error: null
};

export const orgApiKeySlice = createSlice({
  name: 'orgApiKey',
  initialState: initialOrgApiKeyState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadOrgApiKeyAction.pending, (state, action) => ({
      ...state,
      apiKey: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadOrgApiKeyAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          apiKey: action.payload
        },
        loadOrgApiKeyAction
      )
    );
    builder.addCase(loadOrgApiKeyAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadOrgApiKeyAction
      )
    );

    builder.addCase(updateOrgApiKeyNameAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateOrgApiKeyNameAction.fulfilled, state =>
      cleanupPendingState(state, updateOrgApiKeyNameAction)
    );
    builder.addCase(updateOrgApiKeyNameAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateOrgApiKeyNameAction
      )
    );

    builder.addCase(updateOrgApiKeyAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updateOrgApiKeyAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updateOrgApiKeyAction)
    );
    builder.addCase(updateOrgApiKeyAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updateOrgApiKeyAction
      )
    );

    builder.addCase(createOrgApiKeyAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createOrgApiKeyAction.fulfilled, state =>
      cleanupPendingState({ ...state }, createOrgApiKeyAction)
    );
    builder.addCase(createOrgApiKeyAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createOrgApiKeyAction
      )
    );
  }
});

export const orgApiKeyReducer = orgApiKeySlice.reducer;
