import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import {
  addVulnerabilityComment,
  deleteVulnerabilityComment,
  editVulnerabilityComment
} from '../services';

export const addVulnerabilityCommentAction = createAsyncThunk<
  void,
  {
    vulnerabilityId: string;
    text: string;
  },
  { state: RootState }
>(
  'vulnerabilityComments/addVulnerabilityComment',
  async ({ vulnerabilityId, text }, { rejectWithValue }) => {
    try {
      return await addVulnerabilityComment(vulnerabilityId, text);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().vulnerability, addVulnerabilityCommentAction)
  }
);

export const editVulnerabilityCommentAction = createAsyncThunk<
  void,
  {
    commentId: string;
    text: string;
  },
  { state: RootState }
>(
  'vulnerabilityComments/editVulnerabilityComment',
  async ({ commentId, text }, { rejectWithValue }) => {
    try {
      return await editVulnerabilityComment(commentId, text);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().vulnerability, editVulnerabilityCommentAction)
  }
);

export const deleteVulnerabilityCommentAction = createAsyncThunk<
  void,
  {
    commentId: string;
  },
  { state: RootState }
>(
  'vulnerabilityComments/deleteVulnerabilityComment',
  async ({ commentId }, { rejectWithValue }) => {
    try {
      return await deleteVulnerabilityComment(commentId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().vulnerability, deleteVulnerabilityCommentAction)
  }
);
