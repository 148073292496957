export enum CustomApiErrorCode {
  MFA_INVALID_OTP = 'MFA_INVALID_OTP'
}

export interface ApiError {
  readonly code?: CustomApiErrorCode;
  readonly detail?: string;
  readonly errors?: ApiValidationError[];
  readonly title: string;
  readonly type?: ApiErrorType;
}

export interface ApiValidationError {
  readonly detail: string;
  readonly pointer: string;
}

export enum ApiErrorType {
  VALIDATION = 'https://docs.brightsec.com/docs/errors/validation',
  UNKNOWN = 'https://docs.brightsec.com/docs/errors/unknown',
  SESSION_EXPIRED = 'https://docs.brightsec.com/docs/errors/session_expired',
  LOCKOUT_DETECTED = 'https://docs.brightsec.com/docs/errors/lockout_detected',
  IP_RANGES_EMPTY = 'https://docs.brightsec.com/docs/errors/ip_ranges_empty'
}

export const isApiError = (error: unknown): error is ApiError =>
  typeof error === 'object' && error !== null && 'type' in error && !!(error as ApiError).title;

export const isApiErrorOfType = (error: unknown, type: ApiErrorType | ApiErrorType[]): boolean => {
  if (!isApiError(error) || !error.type) {
    return false;
  }
  const typesToCheck = Array.isArray(type) ? type : [type];
  return typesToCheck.includes(error.type);
};
