import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError, ApiKey } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  createPersonalApiKeyAction,
  loadPersonalApiKeyAction,
  updatePersonalApiKeyAction
} from './personal-api-key.actions';

interface PersonalApiKeyState {
  readonly apiKey: ApiKey | null;
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialPersonalApiKeyState: PersonalApiKeyState = {
  apiKey: null,
  pending: [],
  error: null
};

export const personalApiKeySlice = createSlice({
  name: 'personalApiKey',
  initialState: initialPersonalApiKeyState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadPersonalApiKeyAction.pending, (state, action) => ({
      ...state,
      apiKey: null,
      pending: [...state.pending, action]
    }));
    builder.addCase(loadPersonalApiKeyAction.fulfilled, (state, action) =>
      cleanupPendingState(
        {
          ...state,
          apiKey: action.payload
        },
        loadPersonalApiKeyAction
      )
    );
    builder.addCase(loadPersonalApiKeyAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        loadPersonalApiKeyAction
      )
    );

    builder.addCase(updatePersonalApiKeyAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(updatePersonalApiKeyAction.fulfilled, state =>
      cleanupPendingState({ ...state }, updatePersonalApiKeyAction)
    );
    builder.addCase(updatePersonalApiKeyAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        updatePersonalApiKeyAction
      )
    );

    builder.addCase(createPersonalApiKeyAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(createPersonalApiKeyAction.fulfilled, state =>
      cleanupPendingState({ ...state }, createPersonalApiKeyAction)
    );
    builder.addCase(createPersonalApiKeyAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        createPersonalApiKeyAction
      )
    );
  }
});

export const personalApiKeyReducer = personalApiKeySlice.reducer;
