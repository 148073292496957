import type { RouteObject } from 'react-router-dom';

export const userSettingsRoutes: RouteObject[] = [
  {
    path: 'user-settings',
    async lazy() {
      const { UserSettingsPages } = await import('./pages');
      return { Component: UserSettingsPages };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { PersonalSettingsPage } = await import('./pages');
          return { Component: PersonalSettingsPage };
        }
      },
      {
        path: 'account-security',
        async lazy() {
          const { AccoutSecurityPage } = await import('./pages');
          return { Component: AccoutSecurityPage };
        }
      },
      {
        path: 'api-keys',
        async lazy() {
          const { PersonalApiKeysPage } = await import('./pages');
          return { Component: PersonalApiKeysPage };
        },
        children: [
          {
            path: 'new',
            async lazy() {
              const { CreatePersonalApiKeyPage } = await import('./pages');
              return { Component: CreatePersonalApiKeyPage };
            }
          },
          {
            path: ':apiKeyId',
            async lazy() {
              const { EditPersonalApiKeyPage } = await import('./pages');
              return { Component: EditPersonalApiKeyPage };
            }
          }
        ]
      }
    ]
  }
];
