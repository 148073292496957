import { axiosInstance } from '@bright/core';
import type { ManageSsoSettings, SsoBrightMetadata, SsoSettings } from '../models';

export const loadSsoSettings = async (orgId: string): Promise<SsoSettings | undefined> => {
  const response = await axiosInstance.get<SsoSettings | undefined>(`/rest/v1/orgs/${orgId}/sso`);
  return response.data;
};

export const createSsoSettings = async (
  orgId: string,
  payload: ManageSsoSettings
): Promise<SsoSettings> => {
  const response = await axiosInstance.post<SsoSettings>(`/rest/v1/orgs/${orgId}/sso`, payload);
  return response.data;
};

export const updateSsoSettings = async (
  orgId: string,
  payload: ManageSsoSettings
): Promise<SsoSettings> => {
  const response = await axiosInstance.put<SsoSettings>(`/rest/v1/orgs/${orgId}/sso`, payload);
  return response.data;
};

export const loadBrightMetadata = async (orgId: string): Promise<SsoBrightMetadata> => {
  const response = await axiosInstance.get<SsoBrightMetadata>(
    `/rest/v1/orgs/${orgId}/sso/sp/metadata`
  );
  return response.data;
};
