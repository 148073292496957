import { common } from '@mui/material/colors';
import type { ThemeOptions } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import {
  blue,
  brightDark,
  brightLight,
  green,
  grey,
  ocean,
  orange,
  purple,
  red,
  violet,
  yellow
} from './colors';

export const options: ThemeOptions = {
  palette: {
    primary: {
      main: blue['600']
    },
    secondary: {
      main: '#D4D4D4'
    },
    error: {
      main: red['900']
    },
    warning: {
      main: yellow['600']
    },
    info: {
      main: blue['50']
    },
    success: {
      main: '#38983D'
    },
    attention: {
      main: orange['600']
    },
    dark: {
      main: brightDark['600']
    },
    light: {
      main: brightLight['600']
    },
    text: {
      primary: brightDark['600']
    },
    // don't use those colors directly. Use `custom` meaningful alias instead
    colors: {
      blue,
      red,
      yellow,
      orange,
      green,
      purple,
      violet,
      brightDark,
      brightLight,
      grey,
      ocean
    },
    custom: {
      icon: brightDark['400'],
      title: brightDark['900'],
      dateColor: brightDark['200'],
      treeColor: brightLight['900'],
      table: {
        hoverBg: ocean['100'],
        selectedBg: ocean['50'],
        oddRowBg: brightLight['300'],
        evenRowBg: brightLight['600'],
        columnHeaderBg: grey['600'],
        columnHeaderBorder: grey['700'],
        columnHeaderFg: brightDark['900'],
        cellEditorBorder: brightDark['100'],
        cellEditorBorderFocus: blue['600'],
        rowBorder: grey['700'],
        config: {
          dndSlotBorder: brightDark['100'],
          dragIcon: brightDark['400']
        },
        filters: {
          activeBg: grey['700'],
          panelBorder: grey['500'],
          removeIcon: brightDark['400']
        }
      },
      chipBg: grey['600'],
      snackbar: {
        floating: {
          bg: {
            success: green['800'],
            error: red['900'],
            info: blue['600'],
            warning: yellow['600']
          },
          fg: common.white,
          shadow: alpha(brightDark['800'], 0.32)
        },
        inline: {
          bg: {
            success: green['50'],
            error: red['100'],
            info: ocean['50'],
            warning: yellow['50']
          },
          icon: {
            success: green['800'],
            error: red['900'],
            info: blue['600'],
            warning: yellow['800']
          },
          fg: brightDark['600']
        }
      },
      stackedBar: {
        hover: alpha(brightDark['600'], 0.08),
        focus: alpha(brightDark['600'], 0.12)
      },
      severityStackedBar: {
        critical: {
          bg: red['600'],
          fg: common.white
        },
        high: {
          bg: orange['600'],
          fg: common.white
        },
        medium: {
          bg: yellow['600'],
          fg: brightDark['600']
        },
        low: {
          bg: blue['500'],
          fg: common.white
        }
      },
      severity: {
        critical: red['600'],
        high: orange['700'],
        medium: yellow['900'],
        low: blue['600']
      },
      connectivity: {
        ok: {
          bg: green['600'],
          fg: brightLight['50']
        },
        problem: {
          bg: violet['600'],
          fg: brightLight['50']
        },
        skipped: {
          bg: grey['700'],
          fg: brightDark['600']
        },
        unauthorized: {
          bg: purple['600'],
          fg: brightLight['50']
        },
        unreachable: {
          bg: blue['400'],
          fg: brightLight['50']
        }
      },
      spinner: {
        bg: alpha(brightDark['600'], 0.08)
      },
      circularProgressBar: {
        fg: {
          gray: brightDark['500'],
          red: red['600'],
          orange: orange['600'],
          green: green['600']
        },
        bg: {
          gray: brightDark['200'],
          red: red['200'],
          orange: orange['200'],
          green: orange['200']
        }
      },
      healthGauge: {
        grey: {
          bg: grey['600'],
          fg: grey['600']
        },
        red: {
          bg: red['100'],
          fg: red['600']
        },
        orange: {
          bg: orange['100'],
          fg: orange['600']
        },
        green: {
          bg: green['100'],
          fg: green['600']
        }
      },
      repeaterConnectivity: {
        connected: green['600'],
        disconnected: red['600'],
        timedout: brightLight['800'],
        deactivated: yellow['600']
      },
      dialogTabs: {
        labelBorder: grey['900'],
        activeLabelBg: common.white,
        activeLabelBorder: blue['600'],
        hoverBg: {
          active: ocean['50'],
          pending: alpha(brightDark['600'], 0.04),
          invalid: alpha(red['600'], 0.04),
          valid: alpha(green['700'], 0.04)
        },
        hoverBorder: {
          active: blue['600'],
          pending: brightDark['500'],
          invalid: red['800'],
          valid: green['800']
        },
        validIcon: green['700'],
        invalidIcon: red['700'],
        text: brightDark['500']
      },
      tag: {
        default: {
          bg: blue['50'],
          fg: blue['600'],
          focusBg: blue['100'],
          hoverBg: blue['100']
        },
        nonInteractive: {
          bg: brightDark['50'],
          fg: brightDark['600']
        }
      },
      dropdownSelected: ocean['50'],
      avatar: {
        1: yellow['800'],
        2: orange['900'],
        3: red['800'],
        4: green['800'],
        5: violet['800'],
        6: blue['700'],
        7: blue['200'],
        8: yellow['500'],
        9: orange['500'],
        10: red['500'],
        11: green['500'],
        12: violet['500'],
        13: blue['500']
      },
      button: {
        primary: {
          state: {
            enabled: {
              fg: common.white,
              bg: blue['600']
            },
            disabled: {
              fg: common.white,
              bg: grey['800']
            },
            hover: {
              fg: common.white,
              bg: blue['700']
            },
            focus: {
              fg: common.white,
              bg: blue['800']
            }
          }
        },
        primaryError: {
          state: {
            enabled: {
              fg: common.white,
              bg: red['600']
            },
            disabled: {
              fg: common.white,
              bg: grey['800']
            },
            hover: {
              fg: common.white,
              bg: red['700']
            },
            focus: {
              fg: common.white,
              bg: red['800']
            }
          }
        },
        primaryWarning: {
          state: {
            enabled: {
              fg: brightDark['600'],
              bg: yellow['600']
            },
            disabled: {
              fg: common.white,
              bg: grey['800']
            },
            hover: {
              fg: brightDark['600'],
              bg: yellow['700']
            },
            focus: {
              fg: brightDark['600'],
              bg: yellow['800']
            }
          }
        },
        primaryInfo: {
          state: {
            enabled: {
              fg: brightDark['600'],
              bg: blue['200']
            },
            disabled: {
              fg: common.white,
              bg: grey['800']
            },
            hover: {
              fg: brightDark['600'],
              bg: blue['300']
            },
            focus: {
              fg: brightDark['600'],
              bg: blue['400']
            }
          }
        },
        outlinedSecondary: {
          border: grey['900'],
          state: {
            enabled: {
              fg: brightDark['600'],
              bg: 'transparent'
            },
            disabled: {
              fg: grey['900'],
              bg: 'transparent'
            },
            hover: {
              fg: brightDark['600'],
              bg: alpha(brightDark['600'], 0.04)
            },
            focus: {
              fg: brightDark['600'],
              bg: alpha(brightDark['600'], 0.08)
            }
          }
        },
        outlinedPrimary: {
          border: blue['300'],
          state: {
            enabled: {
              fg: blue['600'],
              bg: 'transparent'
            },
            disabled: {
              fg: grey['900'],
              bg: 'transparent'
            },
            hover: {
              fg: blue['600'],
              bg: alpha(blue['600'], 0.04)
            },
            focus: {
              fg: blue['600'],
              bg: alpha(blue['600'], 0.08)
            }
          }
        },
        tertiaryPrimary: {
          state: {
            enabled: {
              fg: blue['600'],
              bg: 'transparent'
            },
            disabled: {
              fg: grey['900'],
              bg: 'transparent'
            },
            hover: {
              fg: blue['600'],
              bg: alpha(blue['600'], 0.04)
            },
            focus: {
              fg: blue['600'],
              bg: alpha(blue['600'], 0.08)
            }
          }
        },
        tertiarySecondary: {
          state: {
            enabled: {
              fg: brightDark['600'],
              bg: 'transparent'
            },
            disabled: {
              fg: grey['900'],
              bg: 'transparent'
            },
            hover: {
              fg: brightDark['600'],
              bg: alpha(brightDark['600'], 0.04)
            },
            focus: {
              fg: brightDark['600'],
              bg: alpha(brightDark['600'], 0.08)
            }
          }
        },
        tertiaryError: {
          state: {
            enabled: {
              fg: red['700'],
              bg: 'transparent'
            },
            disabled: {
              fg: grey['900'],
              bg: 'transparent'
            },
            hover: {
              fg: red['700'],
              bg: alpha(red['600'], 0.04)
            },
            focus: {
              fg: red['700'],
              bg: alpha(red['600'], 0.08)
            }
          }
        }
      },
      splitButton: {
        primary: {
          divider: blue['200']
        }
      },
      dialog: {
        actionsBg: brightLight['600']
      },
      confirmationDialog: {
        icon: {
          primary: {
            bg: blue['200'],
            fg: brightDark['600'],
            outerBg: blue['50']
          },
          warning: {
            bg: yellow['600'],
            fg: brightDark['600'],
            outerBg: yellow['50']
          },
          error: {
            bg: red['600'],
            fg: brightLight['50'],
            outerBg: red['50']
          }
        }
      },
      switch: {
        enabled: {
          base: {
            track: grey['700'],
            thumb: grey['900']
          },
          selected: {
            track: green['800'],
            thumb: green['700']
          }
        },
        disabled: {
          base: {
            track: grey['200'],
            thumb: grey['600']
          },
          selected: {
            track: green['100'],
            thumb: green['200']
          }
        }
      },
      header: {
        appMenu: {
          selected: {
            bg: brightDark['500']
          },
          hover: {
            bg: brightDark['400']
          },
          focus: {
            bg: brightDark['300']
          },
          icon: {
            selected: {
              fill: brightLight['600']
            },
            visible: {
              stroke: blue['600']
            },
            hover: {
              stroke: blue['700']
            },
            predefined: {
              fill: blue['600'],
              stroke: ocean['100']
            }
          }
        },
        divider: brightDark['300'],
        menu: {
          bg: common.white,
          fg: brightDark['600'],
          hover: {
            bg: brightLight['600'],
            fg: brightDark['600']
          }
        }
      },
      caption: brightDark['300'],
      delimiter: grey['600'],
      userProfile: {
        username: brightLight['600'],
        bg: {
          hover: alpha(brightLight['600'], 0.08),
          focus: alpha(brightLight['600'], 0.12)
        }
      },
      sidebar: {
        sectionHeaderFg: brightDark['200'],
        listItem: {
          fg: grey['600'],
          hover: {
            bg: alpha(brightLight['600'], 0.08),
            fg: brightLight['600']
          },
          focus: {
            bg: alpha(brightLight['600'], 0.12),
            fg: brightLight['600']
          },
          disabled: {
            fg: brightDark['500']
          },
          selected: {
            bg: brightLight['600'],
            fg: brightDark['600'],
            hover: {
              bg: brightLight['600'],
              fg: brightDark['600']
            }
          }
        }
      },
      tooltip: {
        light: {
          bg: alpha(brightLight['400'], 0.9),
          fg: brightDark['900'],
          headline: brightDark['200']
        },
        dark: {
          bg: alpha(brightDark['400'], 0.9),
          fg: brightLight['50'],
          headline: brightDark['200']
        },
        shadow: alpha(brightDark['800'], 0.25)
      },
      checkbox: {
        unchecked: {
          enabled: brightDark['500'],
          disabled: grey['800'],
          hover: alpha(brightDark['600'], 0.04)
        },
        checked: {
          enabled: blue['600'],
          disabled: grey['800'],
          hover: alpha(blue['600'], 0.08)
        }
      },
      radio: {
        unchecked: {
          enabled: brightDark['500'],
          disabled: grey['800'],
          hover: alpha(brightDark['600'], 0.04)
        },
        checked: {
          enabled: blue['600'],
          disabled: grey['800'],
          hover: alpha(blue['600'], 0.08)
        }
      },
      input: {
        label: {
          color: brightDark['400'],
          disabled: brightDark['200'],
          focused: blue['600'],
          error: red['900'],
          asterisk: red['900']
        },
        helperText: {
          color: brightDark['400'],
          error: red['900']
        },
        text: brightDark['600'],
        outline: {
          color: grey['800'],
          focused: blue['600'],
          error: red['900']
        }
      },
      select: {
        popupIndicator: brightDark['500'],
        clearIndicator: brightDark['500']
      },
      feedActivity: {
        text: brightDark['600'],
        date: brightDark['200'],
        divider: brightDark['50']
      },
      errorPage: {
        code: ocean['100'],
        link: {
          fg: blue['600'],
          hoverFg: blue['700']
        }
      },
      detailsSidebar: {
        title: brightDark['400'],
        value: brightDark['600']
      },
      menuListItem: {
        primary: {
          enabled: {
            fg: brightDark['600'],
            hover: {
              bg: alpha(brightDark['600'], 0.04)
            }
          },
          disabled: {
            fg: grey['900']
          },
          selected: {
            fg: blue['600'],
            bg: alpha(blue['600'], 0.04),
            hover: {
              bg: alpha(blue['600'], 0.08)
            }
          }
        },
        error: {
          enabled: {
            fg: red['700'],
            hover: {
              bg: alpha(red['700'], 0.04)
            }
          },
          disabled: {
            fg: grey['900']
          }
        },
        expandMoreIcon: brightDark['600'],
        separator: grey['700']
      },
      tabs: {
        border: grey['700'],
        enabled: brightDark['400'],
        disabled: grey['900'],
        selected: blue['600']
      },
      customCard: {
        bg: brightLight['300'],
        headerBorder: grey['700'],
        iconColor: brightDark['400'],
        subTitle: brightDark['300']
      },
      responseStatus: {
        text: brightDark['900']
      },
      logs: {
        level: {
          info: blue['900'],
          warning: yellow['900'],
          error: red['900']
        },
        message: '#000000'
      },
      warningBar: {
        bg: yellow['50'],
        icon: yellow['900']
      },
      infoBar: {
        bg: brightLight['200'],
        method: brightDark['200'],
        caption: brightDark['400'],
        value: brightDark['600'],
        shadow: alpha(brightDark['400'], 0.16)
      },
      entrypointsList: {
        expandIcon: brightDark['400'],
        item: {
          method: {
            default: brightDark['200'],
            selected: blue['500']
          },
          selected: {
            bg: blue['50'],
            fg: blue['600']
          },
          hover: {
            bg: alpha(brightDark['600'], 0.04)
          }
        }
      },
      checkedLabel: {
        checked: green['700'],
        unchecked: red['700']
      },
      apiKeys: {
        status: {
          active: {
            bg: green['100'],
            fg: green['800']
          },
          expired: {
            bg: orange['100'],
            fg: orange['800']
          },
          revoked: {
            bg: red['100'],
            fg: red['800']
          },
          suspended: {
            bg: yellow['50'],
            fg: yellow['900']
          }
        }
      },
      users: {
        status: {
          active: {
            bg: green['100'],
            fg: green['800']
          },
          inactive: {
            bg: yellow['50'],
            fg: yellow['900']
          },
          invited: {
            bg: brightDark['50'],
            fg: brightDark['400']
          }
        }
      },
      bridges: {
        connectivity: {
          connected: green['600'],
          disconnected: brightLight['50'],
          deactivated: brightDark['400'],
          timedout: brightDark['400']
        },
        status: {
          active: {
            bg: green['100'],
            fg: green['800']
          },
          suspended: {
            bg: yellow['50'],
            fg: yellow['900']
          }
        }
      },
      security: {
        info: {
          tooltip: {
            bg: blue['50'],
            fg: brightDark['600']
          },
          icon: blue['600']
        },
        select: {
          value: brightDark['300']
        }
      },
      entrypointDetails: {
        table: {
          key: brightDark['400'],
          value: brightDark['600']
        },
        screenshots: {
          thumbnail: {
            border: brightDark['100'],
            backdrop: alpha(brightDark['900'], 0.72),
            hover: {
              border: brightDark['900']
            }
          },
          fullScreenButton: {
            bg: brightDark['100'],
            fg: brightLight['300']
          },
          moreButton: {
            bg: brightDark['900'],
            fg: brightLight['50']
          },
          modal: {
            arrowColor: brightDark['600'],
            arrowDisabled: grey['900'],
            closeIcon: brightDark['400'],
            imageBorder: blue['600'],
            indexBorder: grey['800'],
            indexText: brightDark['600'],
            modalBackground: brightLight['50'],
            thumbnailBorderHover: brightDark['600'],
            thumbnailBorderFocus: brightDark['600'],
            thumbnailBorderSelected: blue['600'],
            thumbnailBorder: brightDark['100']
          }
        },
        additionalInformation: {
          headline: brightDark['900'],
          text: brightDark['600'],
          link: blue['600']
        }
      },
      entrypointPayload: {
        table: {
          index: brightDark['600'],
          key: brightDark['400'],
          value: brightDark['600']
        },
        headers: {
          bg: grey['100']
        },
        body: {
          bg: grey['100'],
          hex: {
            offset: brightDark['400'],
            code: brightDark['600'],
            content: brightDark['600'],
            delimiter: grey['700']
          }
        },
        icon: brightDark['400']
      },
      diff: {
        added: green['100'],
        removed: red['100']
      },
      codeEditor: {
        bg: grey['100'],
        activeLineBg: alpha(brightDark['600'], 0.04)
      },
      properties: {
        color: brightDark['400'],
        disabledInput: grey['900']
      },
      createdByLabel: {
        text: blue['600'],
        tooltipEmail: brightLight['700'],
        avatar: {
          innerBorder: brightLight['600'],
          outerBorder: blue['600']
        },
        keyIcon: {
          border: brightLight['300'],
          bg: blue['600'],
          fg: common.white
        },
        icon: {
          bg: grey['800'],
          fg: brightDark['300']
        }
      },
      importFileDialog: {
        progressBarBg: grey['700'],
        caption: brightDark['300']
      },
      permissions: {
        icon: {
          bg: brightDark['300'],
          fg: brightDark['50']
        }
      },
      targetSchemaImport: {
        hostStatus: {
          authorized: green['600'],
          unauthorized: red['600'],
          unreachable: blue['500']
        },
        border: grey['700'],
        loadingOverlay: {
          bg: brightLight['50'],
          fg: brightDark['400']
        },
        selectedInput: {
          bg: grey['600'],
          fg: brightDark['400']
        },
        removeButtonHover: brightDark['500']
      },
      integrationStatus: {
        available: {
          bg: 'none',
          fg: 'none'
        },
        enabled: {
          bg: green['700'],
          fg: common.white
        },
        comingSoon: {
          bg: brightLight['600'],
          fg: brightDark['600']
        }
      },
      dashboard: {
        tableBorder: grey['800'],
        barWidgetLabel: brightDark['400'],
        vulnerabilitiesOvertimeChart: {
          series: {
            new: grey['900'],
            open: blue['600'],
            resolved: green['600']
          },
          tooltipSign: brightDark['200']
        },
        chart: {
          grid: grey['700'],
          text: brightDark['400']
        }
      },
      sitemap: {
        primary: brightDark['600'],
        secondary: brightDark['400'],
        method: brightDark['200'],
        rowHover: alpha(brightDark['600'], 0.04)
      },
      auditLog: {
        itemName: brightDark['400'],
        divider: grey['700']
      },
      widget: {
        boxShadow: alpha(brightDark['900'], 0.12)
      },
      entrypointsImportDialog: {
        fileUpload: {
          hover: {
            bg: alpha(brightDark['600'], 0.04),
            border: brightDark['600']
          },
          subtitle: brightDark['200'],
          tabs: {
            selectedBg: brightLight['100'],
            bg: grey['600'],
            hoverFg: brightDark['900']
          }
        }
      },
      stageConfigurationList: {
        expandedHeaderBg: brightLight['500'],
        stageNumberBorder: grey['700'],
        boxShadow: alpha(brightDark['900'], 0.12)
      },
      separator: grey['700'],
      outlinedBorder: grey['900'],
      authRecorderDialog: {
        input: {
          bg: brightLight['600'],
          fg: brightDark['600']
        },
        navbarBorder: brightLight['700'],
        recorderActions: {
          actionBarBg: brightLight['600'],
          actionBarShadowBg: brightDark['600'],
          actionButtonBg: brightLight['700'],
          hoverButton: {
            bg: grey['900'],
            fontColor: brightDark['600']
          }
        }
      },
      applications: {
        application: {
          details: {
            grid: {
              title: brightDark['300'],
              value: brightDark['600']
            }
          }
        },
        confirmationDialog: {
          delete: {
            name: brightDark[900]
          }
        },
        permission: {
          name: blue['400'],
          email: brightDark['400'],
          tag: {
            bg: blue['50'],
            color: blue['400']
          },
          buttons: {
            color: brightDark['400']
          }
        }
      }
    },
    // New colors based on the design system
    new: {
      bg: {
        primary: {
          trust: {
            300: '#A9D6F7',
            400: '#7EBEF3',
            500: '#58ACF0',
            600: '#1976D2',
            700: '#1562BC',
            800: '#1B56A8'
          },
          energy: {
            600: '#E00000',
            700: '#D10202',
            800: '#B80101'
          },
          joy: {
            600: '#FAD649',
            700: '#F0C825',
            800: '#D6B018'
          },
          growth: {
            600: '#008A2E',
            700: '#007B29',
            800: '#007527'
          },
          innocence: {
            900: '#CAD1D7'
          }
        },
        secondary: {
          trust: {
            100: 'rgb(25 118 210 / 6%)',
            200: 'rgb(25 118 210 / 10%)'
          },
          energy: {
            100: 'rgb(224 0 0 / 6%)',
            200: 'rgb(224 0 0 / 10%)'
          },
          joy: {
            100: 'rgb(179 143 0 / 6%)',
            200: 'rgb(179 143 0 / 10%)'
          },
          growth: {
            100: 'rgb(1 97 33 / 6%)',
            200: 'rgb(1 97 33 / 10%)'
          },
          mystery: {
            100: 'rgb(6 10 35 / 6%)',
            200: 'rgb(6 10 35 / 10%)'
          }
        }
      },
      text: {
        secondary: {
          trust: {
            500: '#3199EC',
            600: '#1976D2'
          },
          energy: {
            600: '#E00000'
          },
          joy: {
            600: '#B38F00'
          },
          innocence: {
            50: '#ffffff',
            100: '#CAD1D7',
            600: '#9C9C9C'
          },
          mystery: {
            600: '#060A23',
            900: '#00010A'
          },
          growth: {
            600: '#008A2E'
          }
        }
      },
      stroke: {
        secondary: {
          innocence: {
            100: '#CAD1D7'
          }
        }
      }
    }
  }
};
