/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ReactNode } from 'react';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type CustomTooltipProps = {
  text?: ReactNode | null;
  headline?: string;
  withHeadline?: boolean;
  maxWidth?: number;
  variant?: 'dark' | 'light';
} & Omit<TooltipProps, 'title'>;

export const CustomTooltip = styled(
  ({
    className,
    text,
    headline,
    maxWidth,
    variant = 'dark',
    withHeadline,
    ...props
  }: CustomTooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      // eslint-disable-next-line react/no-children-prop
      children={props.children}
      enterDelay={1000}
      title={
        text ? (
          <>
            {headline && (
              <Typography
                sx={{
                  fontSize: 12,
                  color: theme => theme.palette.custom.tooltip[variant].headline,
                  lineHeight: theme => theme.spacing(2.25)
                }}
              >
                {headline}
              </Typography>
            )}
            {text}
          </>
        ) : null
      }
    />
  )
)(({ theme, maxWidth, variant = 'dark', withHeadline = false }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...(maxWidth ? { maxWidth } : {}),
    fontSize: withHeadline ? 14 : 12,
    padding: withHeadline ? theme.spacing(0, 2) : theme.spacing(0.25, 0.75),
    backgroundColor: theme.palette.custom.tooltip[variant].bg,
    color: theme.palette.custom.tooltip[variant].fg,
    textAlign: withHeadline ? 'center' : 'left'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.custom.tooltip[variant].bg
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
    marginTop: theme.spacing(0.5)
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
    marginBottom: theme.spacing(0.5)
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
    marginLeft: theme.spacing(0.5)
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
    marginRight: theme.spacing(0.5)
  }
}));
