import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ApiError } from '@bright/api';
import { cleanupPendingState } from '@bright/core';
import {
  addVulnerabilityCommentAction,
  deleteVulnerabilityCommentAction,
  editVulnerabilityCommentAction
} from './vulnerability-comments.actions';

export interface VulnerabilityCommentsState {
  readonly pending: Action[];
  readonly error: ApiError | null;
}

const initialVulnerabilityState: VulnerabilityCommentsState = {
  pending: [],
  error: null
};
0;

export const vulnerabilityCommentsSlice = createSlice({
  name: 'vulnerabilityComments',
  initialState: initialVulnerabilityState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addVulnerabilityCommentAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(addVulnerabilityCommentAction.fulfilled, state =>
      cleanupPendingState(state, addVulnerabilityCommentAction)
    );
    builder.addCase(addVulnerabilityCommentAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        addVulnerabilityCommentAction
      )
    );

    builder.addCase(editVulnerabilityCommentAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(editVulnerabilityCommentAction.fulfilled, state =>
      cleanupPendingState(state, editVulnerabilityCommentAction)
    );
    builder.addCase(editVulnerabilityCommentAction.rejected, (state, action: PayloadAction<any>) =>
      cleanupPendingState(
        {
          ...state,
          error: action.payload
        },
        editVulnerabilityCommentAction
      )
    );

    builder.addCase(deleteVulnerabilityCommentAction.pending, (state, action) => ({
      ...state,
      pending: [...state.pending, action]
    }));
    builder.addCase(deleteVulnerabilityCommentAction.fulfilled, state =>
      cleanupPendingState(state, deleteVulnerabilityCommentAction)
    );
    builder.addCase(
      deleteVulnerabilityCommentAction.rejected,
      (state, action: PayloadAction<any>) =>
        cleanupPendingState(
          {
            ...state,
            error: action.payload
          },
          deleteVulnerabilityCommentAction
        )
    );
  }
});

export const vulnerabilityCommentsReducer = vulnerabilityCommentsSlice.reducer;
