import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectOwnOrganizationId } from '@bright/auth/store';
import type { RootState } from '@bright/core';
import { actionNotPending } from '@bright/core';
import type { ManageSsoSettings, SsoBrightMetadata, SsoSettings } from '../models';
import {
  createSsoSettings,
  loadBrightMetadata,
  loadSsoSettings,
  updateSsoSettings
} from '../services';

export const loadSsoSettingsAction = createAsyncThunk<
  SsoSettings | undefined,
  void,
  { state: RootState }
>(
  'ssoSettings/loadSsoSettings',
  async (_, { rejectWithValue, getState }) => {
    const orgId = selectOwnOrganizationId(getState());

    try {
      return await loadSsoSettings(orgId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => actionNotPending(getState().ssoSettings, loadSsoSettingsAction)
  }
);

export const updateSsoSettingsAction = createAsyncThunk<
  SsoSettings,
  ManageSsoSettings,
  { state: RootState }
>(
  'ssoSettings/updateSsoSettings',
  async (ssoSettings, { rejectWithValue, getState }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await updateSsoSettings(orgId, ssoSettings);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().ssoSettings, updateSsoSettingsAction)
  }
);

export const createSsoSettingsAction = createAsyncThunk<
  SsoSettings,
  ManageSsoSettings,
  { state: RootState }
>(
  'ssoSettings/createSsoSettings',
  async (ssoSettings, { rejectWithValue, getState }) => {
    const orgId = selectOwnOrganizationId(getState());
    try {
      return await createSsoSettings(orgId, ssoSettings);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().ssoSettings, createSsoSettingsAction)
  }
);

export const skipSsoTestAction = createAsyncThunk<void, void, { state: RootState }>(
  'ssoSettings/skipSsoTest',
  async () => {
    return await Promise.resolve();
  }
);

export const loadBrightMetadataAction = createAsyncThunk<
  SsoBrightMetadata,
  void,
  { state: RootState }
>(
  'ssoSettings/loadBrightMetadataAction',
  async (_, { rejectWithValue, getState }) => {
    const orgId = selectOwnOrganizationId(getState());

    try {
      return await loadBrightMetadata(orgId);
    } catch (err: unknown) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().ssoSettings, loadBrightMetadataAction)
  }
);
