import { createAsyncThunk } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import type {
  ApiError,
  ApiKey,
  ApiKeysBulkAction,
  ExportFormat,
  PaginationResponse
} from '@bright/api';
import type { RootState } from '@bright/core';
import {
  actionNotPending,
  convertAxiosHeadersToDomHeaders,
  getFileNameFromHeaders
} from '@bright/core';
import {
  exportPersonalApiKeys,
  loadPersonalApiKeys,
  runPersonalApiKeysBulkAction,
  updatePersonalApiKeyName
} from '../services';

export const loadPersonalApiKeysAction = createAsyncThunk<
  PaginationResponse<ApiKey>,
  {
    queryString: string;
  },
  { state: RootState }
>(
  'personalApiKeys/loadPersonalApiKeys',
  async ({ queryString }, { rejectWithValue }) => {
    try {
      return await loadPersonalApiKeys(queryString);
    } catch (err) {
      return rejectWithValue(err as unknown as ApiError);
    }
  },
  {
    condition: (_, { getState }) =>
      actionNotPending(getState().personalApiKeys, loadPersonalApiKeysAction)
  }
);

export const runPersonalApiKeysBulkActionAction = createAsyncThunk<
  string[] | void,
  {
    ids: string[];
    action: ApiKeysBulkAction;
  },
  { state: RootState }
>('personalApiKeys/runPersonalApiKeysBulkAction', async ({ ids, action }, { rejectWithValue }) => {
  try {
    return await runPersonalApiKeysBulkAction(ids, action);
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const updatePersonalApiKeyNameAction = createAsyncThunk<
  { apiKeyId: string; name: string },
  {
    apiKeyId: string;
    data: Pick<ApiKey, 'name'>;
  },
  { state: RootState }
>('personalApiKeys/updatePersonalApiKeyName', async ({ apiKeyId, data }, { rejectWithValue }) => {
  try {
    await updatePersonalApiKeyName(apiKeyId, data);
    return { apiKeyId, name: data.name };
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});

export const exportPersonalApiKeysAction = createAsyncThunk<
  void,
  {
    ids: string[];
    format: ExportFormat;
  },
  { state: RootState }
>('personalApiKeys/exportPersonalApiKeysAction', async ({ ids, format }, { rejectWithValue }) => {
  try {
    const response = await exportPersonalApiKeys(format, ids);
    const name = getFileNameFromHeaders(convertAxiosHeadersToDomHeaders(response.headers));
    fileSaver.saveAs(response.data, sanitize(name, { replacement: '_' }));
  } catch (err) {
    return rejectWithValue(err as unknown as ApiError);
  }
});
